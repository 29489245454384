import React from 'react';
import Logo from '../assets/logo.png';

function Header() {
  const toggleProfileMenu = () => {
    const profileMenu = document.getElementById("profileMenu");
    const svg = document.querySelector(".profile-title svg");
    profileMenu?.classList.toggle("active");
    svg?.classList.toggle("transform");
  };

  const toggleSidenav = () => {
    const sidenav = document.getElementById("mySidenav");
    const opacity = document.getElementById("opacity");
    sidenav?.classList.toggle("open");
    opacity?.classList.toggle("opa");
    document.body.classList.toggle("opa");
  };

  return (
    <header>
      <div className="container">
        <div className="header">
          <span className="logo">
            <a href="/">
              <img className="logo" src={Logo} alt="Logo" />
            </a>
          </span>
          <nav className="navigation">
            <ul>
              <li>
                <a className="navigation-link" href="/">Home</a>
              </li>
              <li className="vertical_separator"></li>
              <li className='navigation-icon'>
                <a href="https://api.whatsapp.com/send?phone=541131495030" target='_blank' rel="noopener noreferrer">
                  <span className="whatsapp"></span>
                </a>
              </li>
              {/* Profile menu code commented out */}
            </ul>
          </nav>
          <div className="profile_icon-mobile">
          
            <div className="container-togg" onClick={toggleSidenav}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
          </div>
        </div>
        <div id="mySidenav" className="sidenav">
        <ul>
              <li>
                <a className="navigation-link" href="/">Home</a>
              </li>
            </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;