import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const clientId = '58de8afcf8824ceb9d0aaa274ae582e3';
const clientSecret = '011e3367c9b74c228bbc3ef691e4ba2a';
export const spotifyApi = createApi({
    reducerPath: 'spotifyApi',
    baseQuery: fetchBaseQuery({ 
      baseUrl: 'https://api.spotify.com/v1/',
      prepareHeaders: async (headers, { getState, endpoint }) => {
        if (endpoint === 'getAccessToken') return headers;
  
        const rootState = getState();
        const { data: accessToken } = rootState.spotifyApi.queries['getAccessToken(undefined)'] || {};
  
        if (accessToken) {
          headers.set('Authorization', `Bearer ${accessToken}`);
        }
        return headers;
      },
    }),
    endpoints: (builder) => ({
      getAccessToken: builder.query({
        query: () => ({
          url: 'https://accounts.spotify.com/api/token',
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
          },
          body: 'grant_type=client_credentials',
        }),
        transformResponse: (response) => response.access_token,
      }),
      getUserPlaylists: builder.query({
        query: (userId) => `users/${userId}/playlists?limit=50&offset=0`,
      }),
      getArtist: builder.query({
        query: (artist) => `artists/${artist}`,
      }),
    }),
  });
  
  export const { useGetAccessTokenQuery, useLazyGetUserPlaylistsQuery, useLazyGetArtistQuery } = spotifyApi;